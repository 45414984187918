import { breakpointsTailwind } from '@vueuse/core'

export function useResponsive() {
  const isResponsiveInit = useState('isResponsiveInit', () => false)

  const responsive = useState<{
    desktop: boolean | undefined
    tablet: boolean | undefined
    mobile: boolean | undefined
  }>('responsive', () => ({
    desktop: undefined,
    tablet: undefined,
    mobile: undefined,
  }))

  function minWidth(width: number): boolean {
    return window.matchMedia(`(min-width: ${width}px)`).matches
  }

  function handleWindowResize() {
    if (minWidth(breakpointsTailwind.xl)) {
      responsive.value.desktop = true
      responsive.value.tablet = false
      responsive.value.mobile = false
    }
    else if (minWidth(breakpointsTailwind.sm)) {
      responsive.value.desktop = false
      responsive.value.tablet = true
      responsive.value.mobile = false
    }
    else {
      responsive.value.desktop = false
      responsive.value.tablet = false
      responsive.value.mobile = true
    }

    isResponsiveInit.value = true
  }

  if (process.client) {
    // wait to nuxt rerender
    setTimeout(() => {
      window.addEventListener('resize', () => handleWindowResize())
      if (!isResponsiveInit.value) {
        handleWindowResize()
      }
    }, 10)
  }

  return responsive
}
